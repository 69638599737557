import React from "react";
import { MemoizedArtDirectedImage } from "components/Design/ArtDirectedImage";
import IconFromBlock from "./IconFromBlock";
import { motion } from "framer-motion";

export const BannerImage = ({ block }) => {
  const { caption, bannerImageIcon, title, subtitle, mobile, desktop } = block;

  return (
    <div className="relative flex">
      <MemoizedArtDirectedImage
        image={{ mobile, desktop }}
        className="w-full"
        loading="eager"
      />
      <div className="bg-gradient-header absolute w-full h-full top-0 pointer-events-none z-1"></div>
      <div className="absolute z-1 top-1/2 transform transition-none left-1/2 -translate-x-1/2 w-full md:w-3/4 lg:w-6/12 pointer-events-none antialiased px-24 md:px-auto">
        <motion.div
          initial={{ opacity: 0, y: 0 }}
          animate={{ opacity: 1, y: "-50%" }}
          className="transition-all transform duration-2000 ease-in-out text-white text-center w-">
          <div>
            {bannerImageIcon && (
              <IconFromBlock name={bannerImageIcon} color="text-white" />
            )}
            <p className="mt-24 uppercase text-hl-1 font-mulish md:font-bold mb-14 tracking-16">
              {caption}
            </p>
            <h1 className="font-semibold text-36 leading-50 lg:text-52 lg:leading-72">
              {title}
            </h1>
            <p className="font-bold uppercase font-mulish text-16 leading-26 tracking-16">
              {subtitle}
            </p>
          </div>
        </motion.div>
      </div>
    </div>
  );
};
