import cx from "classnames";
import { BannerImage } from "components/BannerImage";
import { ArtDirectedImage } from "components/Design/ArtDirectedImage";
import PrimaryButtonWithIcon from "components/Design/Button/PrimaryButtonWithIcon";
import { CardHighlightRoom } from "components/Design/Card";
import { CardContactUs } from "components/Design/Card/CardContactUs";
import Grid from "components/Design/Grid/Grid";
import PageSection from "components/Design/PageSection/PageSection";
import StoriesWithPopup from "components/Design/StoriesWithPopup/StoriesWithPopup";
import Layout from "components/Layout/Layout";
import PageContext from "contexts/PageContext";
import { graphql } from "gatsby";
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import React, { useState } from "react";
import { BALI } from "store/constants/destinations";
import { AYANA_BALI, ROCKBAR_RESTAURANT } from "store/constants/hotel";
import { AYANA_ROCKBAR_BALI_WHATSAPP_LINK } from "store/constants/socmedLink";
import { GENERAL_INQUIRY } from "store/constants/support";
import { generateSupportURL } from "utils/generateSupportURL";
import { inclusionsToList } from "utils/inclusionToList";
import { transformObject } from "utils/transformv4";
import { whatsAppUrl } from "utils/whatsAppUrl";

const RockbarPageTemplate = ({ data }) => {
  const { t } = useTranslation("homepage");
  const { language } = useI18next();
  const [showBanner, setShowBanner] = useState(false);
  const {
    cms,
    allNavigationMenuJson: {
      nodes: [navigations],
    },
    allSocialMediaJson: {
      nodes: [socialMediaList],
    },
  } = data;

  const {
    page: [{ seo_tags, banners, sections }],
    hotelsByLocale: [hotel],
    sections: [destinations],
    supportDrawer: [supportDrawer],
    hotelPropertyBali,
  } = transformObject(cms);

  const supportDrawerData = {
    ...supportDrawer,
    hotels: hotelPropertyBali,
    hotel: hotel,
  };

  const rockbarIntro = sections.find(
    (section) => section.name === "BALI_Dining_RockBar-Detail_Intro"
  );

  const rockbarDetailSection = sections.filter(
    (section) => section.name === "BALI_Dining_RockBar-Detail_Description"
  );
  const {
    images: [bannerImage],
    ...banner
  } = banners.find((b) => b.name === "BALI_Dining_RockBar_Banner");

  const pageWhatsappLink =
    hotel.location === BALI
      ? AYANA_ROCKBAR_BALI_WHATSAPP_LINK
      : whatsAppUrl(hotel.whatsapp_contact, hotel.custom_whatsapp_message);

  const localeAwareRestaurant =
    language === "en"
      ? rockbarDetailSection[0].restaurants[0]
      : rockbarDetailSection[0].restaurants[0].localizations.find(
          (res) => res.locale === language
        );

  const CTAContactURL = localeAwareRestaurant.tablecheck_url
    ? localeAwareRestaurant.tablecheck_url
    : whatsAppUrl(
        localeAwareRestaurant.whatsapp_contact,
        localeAwareRestaurant.whatsapp_message
      );

  const onContactIconClick = () => window.open(CTAContactURL, "_blank");

  const rockBarStories = rockbarDetailSection
    .map((s) => s.cards.map((card) => card))
    .flat();

  return (
    <PageContext.Provider value={{ location: AYANA_BALI }}>
      <Layout
        whatsAppLink={pageWhatsappLink}
        destination={t("Bali")}
        banner={
          <BannerImage
            block={{
              ...bannerImage,
              subtitle: banner.subtitle,
              title: banner.title,
              caption: t("Rockbar"),
              bannerImageIcon: "dish",
            }}
          />
        }
        seoTitle={t("Ayana Bali Activities")}
        seoTags={seo_tags}
        restaurant={ROCKBAR_RESTAURANT}
        destinationCards={destinations.images}
        showBanner={showBanner}
        navigations={navigations}
        hotel={{ ...hotel, ...hotel.destinations[0] }}
        socialMedia={socialMediaList}
        socialMediaLabel={t("Connect with AYANA Bali")}
        supportDrawer={supportDrawerData}
        hotelPropertyBali={hotelPropertyBali}>
        <PageSection
          className="pt-40 pb-4 md:pb-8"
          icon={
            <div className="mx-auto mb-6 md:mb-4 pb-20">
              <img
                src="https://s3.ap-southeast-1.amazonaws.com/cms-asset.ayana.com/Rock_Bar_New_Logo_11a246f4d1.png"
                alt="Rock Bar"
                className="w-106 h-90"
              />
            </div>
          }
          description={
            <span className="text-24-32">{rockbarIntro.description}</span>
          }
        />
        <div className="w-full lg:w-1000 mx-auto lg:pb-60 pb-40">
          <PrimaryButtonWithIcon
            label={t("Book a table")}
            onClick={onContactIconClick}
          />
        </div>
        <div className="bg-black-eerie overflow-x-hidden">
          {rockbarDetailSection.map(
            ({ title, description, images, restaurants }) => {
              return (
                <PageSection
                  className="py-40"
                  title={
                    <h2 className="text-white text-left md:text-center">
                      {title}
                    </h2>
                  }>
                  <div className="flex px-20 md:px-0 md:justify-center text-10-16 text-gray-light">
                    <StoriesWithPopup storyCards={rockBarStories} />
                  </div>
                  <Grid>
                    <div className="max-w-490 col-span-5 md:col-start-2 md:col-span-5 lg:col-start-4 lg:col-span-7">
                      <p className="text-14-24 font-mulish text-white py-24">
                        {description}
                      </p>
                      <div className="w-full grid grid-cols-2 gap-12 py-24">
                        {images.map(({ mobile, desktop }, idx) => (
                          <ArtDirectedImage
                            image={{ mobile, desktop }}
                            imgClassName="rounded-sm"
                            className={cx("h-275 rounded-sm", {
                              "max-w-full col-span-2 md:w-490":
                                images.length - 1 === idx,
                            })}
                          />
                        ))}
                      </div>
                      {restaurants.map(({ dress_code, timings }) => {
                        const inclusionTimings = inclusionsToList({
                          inclusions: timings,
                        });
                        return (
                          <>
                            <CardHighlightRoom
                              subtitle={`${t("Daily Operating Hours")}:`}
                              policyDetail={inclusionTimings.map((time) => (
                                <div>{time}</div>
                              ))}
                              type="white"
                            />
                            <CardHighlightRoom
                              subtitle={`${t("Dress Code")}:`}
                              policyDetail={dress_code}
                              type="white"
                            />
                          </>
                        );
                      })}
                    </div>
                  </Grid>
                </PageSection>
              );
            }
          )}
        </div>
        <CardContactUs
          description={t("contactUsText")}
          label={t("Contact Us")}
          url={generateSupportURL("bali", GENERAL_INQUIRY)}
        />
      </Layout>
    </PageContext.Provider>
  );
};
export const query = graphql`
  query RockbarTemplate($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allNavigationMenuJson(filter: { hotel: { eq: "AYANA Bali" } }) {
      ...LocalNavigation
    }
    allSocialMediaJson(filter: { hotel: { eq: "AYANA Bali" } }) {
      nodes {
        hotel
        socmed {
          link {
            en
            ja
          }
          iconName
        }
      }
    }

    cms {
      sections(filters: { name: { eq: "Bali_Footer_Destination" } }) {
        ...NavSections
      }
      supportDrawer: sections(filters: { name: { eq: "KD_Support_Drawer" } }) {
        ...NavSections
      }
      page: pagesByLocale(language: $language, where: { id: "85" }) {
        data {
          id
          attributes {
            name
            ...CmsPageSeoTags_v4
            banners {
              ...CmsBannerFullWidth_v4
            }
            sections {
              data {
                attributes {
                  ...CmsSection_v4
                  cards {
                    ...CmsCardFullWidth_v4
                  }
                  images(sort: "sequence") {
                    ...CmsImageFullWidth_v4
                  }
                  restaurants {
                    data {
                      attributes {
                        tablecheck_url
                        whatsapp_message
                        whatsapp_contact
                        dress_code
                        timings
                        localizations {
                          data {
                            attributes {
                              tablecheck_url
                              whatsapp_message
                              whatsapp_contact
                              locale
                              dress_code
                              timings
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      hotelsByLocale(language: $language, where: { hotel_code: "ARSB" }) {
        data {
          id
          attributes {
            ...NavHotel
          }
        }
      }
      hotelPropertyBali: hotelsByLocale(
        language: $language
        where: { id: [1, 2, 3, 9] }
        sort: "id:DESC"
      ) {
        data {
          id
          attributes {
            name
            title
            logo {
              ...CmsUploadFileEntityResponse
            }
          }
        }
      }
    }
  }
`;

export default RockbarPageTemplate;
