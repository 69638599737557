import React from "react";

const PageSection = ({
  icon,
  logo,
  title,
  subHeading,
  description,
  link,
  children,
  bottomCta,
  className,
}) => {
  return (
    <section className={className}>
      <div className="grid grid-cols-1 whitespace-pre-wrap">
        {subHeading && (
          <div className="subheading uppercase px-20 text-gold mx-auto mb-12 lg:mb-17 lg:text-12-18 text-12-22 font-mulish font-bold text-center">
            <span className="mr-10">——</span>
            <span className="tracking-16">{subHeading}</span>
            <span className="ml-10">——</span>
          </div>
        )}
        {icon && <div className="mx-auto mb-6 lg:mb-4">{icon}</div>}
        {logo && <div className="mx-auto">{logo}</div>}
        {title && (
          <h2 className="text-center text-gray-text px-20 mx-auto text-m-h2 font-semibold mt-12 mb-16 lg:text-m-h1 lg:mt-16 lg:mb-20 w-full lg:w-inherit lg:max-w-540">
            {title}
          </h2>
        )}
        {description && (
          <p className="lg:mx-auto px-20 mb-16 text-hl-1 text-left lg:text-center lg:max-w-540 lg:mb-32">
            {description}
          </p>
        )}
        {link && (
          <div className="mx-auto px-20 mb-16  text-center md:max-w-540 md:mb-32">
            {link}
          </div>
        )}
        {children}
      </div>

      {bottomCta && (
        <div className="grid grid-cols-1">
          <div className="mx-20 lg:mx-auto inline-block mt-24 lg:mt-32">
            {bottomCta}
          </div>
        </div>
      )}
    </section>
  );
};

PageSection.defaultProps = {
  title: "",
};

export default PageSection;
