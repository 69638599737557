import React from "react";
// TODO: Move this out of the folder

// On mobile:
//   - 1 column
//   - margin of 20px
// On desktop:
//   - 12 columns
//   - gap of 20px
//   - max width is 1000px
//   - center aligned
export const Grid = ({ children }) => (
  <div className="grid grid-cols-1 gap-y-20 lg:grid-cols-12 lg:gap-x-20">
    {children}
  </div>
);

// Container is nothing but a box which has:
// - mobile: 20px margin
// - desktop: 1000px center aligned
export const Container = ({ children }) => (
  <div className="mx-20 lg:max-w-1000 lg:mx-auto">{children}</div>
);

// Useful constants which can be used for calculations
export const CONTAINER_MARGIN = 20;

const GridRow = ({ children }) => (
  <div className="w-full lg:max-w-1040 grid grid-cols-5 lg:grid-cols-12 gap-x-20 mx-auto px-20">
    {children}
  </div>
);

export default GridRow;
