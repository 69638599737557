import React from "react";
import { LAKODIA_LOGO } from "store/constants/blockThemes";
import Icon from "./CommonIcon/Icon";
import { LakodiaLogoFooter } from "./CommonIcon/LakodiaLogo";

const IconFromBlock = ({ name, ...props }) => {
  if (name === LAKODIA_LOGO) {
    return <LakodiaLogoFooter />;
  }

  if (name === "ROCKBAR_BALI") {
    return (
      <img
        src="https://s3.ap-southeast-1.amazonaws.com/cms-asset.ayana.com/Rock_Bar_New_Logo_11a246f4d1.png"
        alt="Rock Bar"
        className="w-106 h-90"
      />
    );
  }

  if (name) {
    return <Icon name={name.split("_").join("-").toLowerCase()} {...props} />;
  }

  return null;
};

export default IconFromBlock;
