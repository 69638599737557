export const NAVBAR = "NAVBAR";
export const FOOTER = "FOOTER";

export const RECOMMENDATIONS = "RECOMMENDATIONS";

// Contact us themes
export const WHATSAPP = "WHATSAPP";

// Sticky CTA themes
export const CONTACT_US = "CONTACT_US";
export const BOOKING_FORM = "BOOKING_FORM";
export const HIDE = "HIDE";

// Slider theme
export const HIGHLIGHTS_SINGLE_CARD = "HIGHLIGHTS_SINGLE_CARD";

// Tab theme
export const CENTER = "CENTER";
export const HIGHLIGHT = "HIGHLIGHT";

// Icon theme
export const LAKODIA_LOGO = "LAKODIA_LOGO";
