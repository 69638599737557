import React from "react";
import Icon from "components/CommonIcon/Icon";
import PrimaryButton from "./PrimaryButton";

const PrimaryButtonWithIcon = ({ iconName, label, onClick, fullWidth }) => {
  return (
    <div className="w-fit-content mx-auto">
      <PrimaryButton
        block={fullWidth}
        label={
          <div className="w-fit-content m-auto">
            <div className="flex items-center">
              {iconName && (
                <Icon name={iconName} fontSize="text-14" color="text-white" />
              )}
              <span className="flex uppercase font-mulish items-center text-12-22 font-semibold">
                {label}
              </span>
            </div>
          </div>
        }
        onClick={onClick}
      />
    </div>
  );
};

export default PrimaryButtonWithIcon;
